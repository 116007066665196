import React, { useState, useContext } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet'
import {
  Button,
  Icon,
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarItem,
  NavbarMenu,
  NavbarStart
} from 'bloomer';

import codeLogo from '../../content/assets/wannyanclub-icon-144.png';

import { GenerateFinancialReportButton } from '../components/generate-financial-report-button'
import { FileSelector } from '../components/file-selector'


// Theme
import { ThemeContext, getOppositeTheme } from '../contexts/theme';



export const Svc802WanNyanAccounts = props => {


  return(
    <div>
      <Helmet>
        <script type="text/javascript" src=""></script>
      </Helmet>

      <FileSelector></FileSelector>
    </div>
  );

}

export default Svc802WanNyanAccounts;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        keywords
        author
      }
    }
  }
`;



